const Map = () => {
  return (
    <div>
      <img
        src="/images/google-map3.jpg"
        alt=""
        className="w-full h-[300px] object-cover"
      />
    </div>
  );
};

export default Map;
