const Hero = () => {
  return (
    <div
      className="relative z-0 flex-wrap min-h-screen gap-2 md:-mt-28 flex-center-center"
      style={{
        background: "url('/images/hero-bg-pattern.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div className="absolute top-0 right-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>
      
      {/* Sol içerik bölümü */}
      <div className="flex-1 basis-full md:basis-[20rem] mb-0 md:mb-20 md:ml-40 p-5 md:p-0 text-center md:text-left -mt-32 md:mt-4">
        <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold capitalize">
          Birlik İnşaat <br />
          <span className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold ml-0 md:ml-8">
            "Konut Mimarı"
          </span>
        </h1>
        <div className="pl-0 md:pl-3 mt-5 md:border-l-4 border-primary">
          <p className="text-sm md:text-base">
            Bugüne kadar 60 aya varan sabit fiyat garantisi ile <br /> binlerce müşterimizi
            konut ve işyeri sahibi yaptık.
          </p>
        </div>

        {/* Proje ve müşteri bilgileri */}
        <div className="mt-6 -mb-24 md:-mb-0 flex justify-around md:justify-start text-center flex-align-center gap-x-6">
          <div>
            <h1 className="text-xl md:text-2xl font-bold">
              100 <span className="text-sm text-primary">+</span>
            </h1>
            <p className="text-xs md:text-sm">İstenen Projeler</p>
          </div>
          <div>
            <h1 className="text-xl md:text-2xl font-bold">
              100 <span className="text-sm text-primary">+</span>
            </h1>
            <p className="text-xs md:text-sm">Tamamlanan Projeler</p>
          </div>
          <div>
            <h1 className="text-xl md:text-2xl font-bold">
              1000 <span className="text-sm text-primary">+</span>
            </h1>
            <p className="text-xs md:text-sm">Müşterilerimiz</p>
          </div>
        </div>
      </div>
      
      {/* Sağ görsel bölümü */}
      <div className="flex-1 basis-full md:basis-[10rem] -mt-96 md:-mt-80 md:-ml-40">
        <img
          src="/images/hero-4.png"
          alt=""
          className="w-full max-w-xs md:max-w-full mx-auto"
        />
      </div>
    </div>
  );
};

export default Hero;