const Stats = () => {
  return (
    <div className="pt-10 pb-16">
      <div className="text-center mb-10">
        <h1 className="heading mt-0 -mb-4 md:mb-4">
          Hakkımızda
        </h1>
      </div>
      <div className="flex flex-wrap gap-24 mt-8">
        <div className="relative flex-1 basis-[18rem] -mb-8">
          <img
            src="/images/birlik10.jpg"
            alt=""
            className="object-cover w-full h-full rounded-lg"
          />
          <div className="absolute w-4/5 -translate-x-1/2 border-4 border-white rounded-lg -bottom-10 left-1/2 dark:border-main-dark bg-secondary text-slate-200 md:text-base text-sm md:p-3 p-2">
            <div className="gap-5 flex-center-between">
              <h1 className="font-medium">
                Ofisimizde bizi ziyaret edebilirsiniz. <br />Kale Mahallesi Sakarya Caddesi <br /> No:59 Samsun/İlkadım
              </h1>

              <div>
                <h1 className="text-xl font-bold text-primary ml-6 md:text-2xl">1000+</h1>
                <p className="text-xs md:text-base ml-7">Binlerce müşteri</p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative flex-1 basis-[22rem]">
          <p>
            İnşaat sektöründe 30 yıllık geçmişe sahip Birlik İnşaat geleceğin teknolojisini kullanarak
            fonksiyonel insan ve çevre odaklı estetik tasarımlara sahip proje inşaa etmeye özen göstermiştir.
          </p>
          <p className="mt-3">
            Firmamız inşaat sektöründe kalite, güvenli ve faizsiz sabit fiyat 60 ay ödeme seçenekleriyle
            binlerce müşterimizi daire sahibi yaptı. Bizleri motive eden müşterilerimizin desteğiyle bundan
            sonra da daha büyük başarılara imza atacağız.
          </p>
          <div className="mt-12">
            <div className="mt-2">
              <div className="flex-center-between">
                <h1 className="font-semibold capitalize">Tamamlanan Projeler</h1>
                <h1 className="font-semibold capitalize">80%</h1>
              </div>
              <div className="w-full h-2 mt-2 overflow-hidden rounded-full bg-slate-100 dark:bg-dark-light">
                <div className="w-4/5 h-full rounded-full bg-secondary"></div>
              </div>
            </div>

            <div className="mt-2">
              <div className="flex-center-between">
                <h1 className="font-semibold capitalize">
                  Ailemiz
                </h1>
                <h1 className="font-semibold capitalize">70%</h1>
              </div>
              <div className="w-full h-2 mt-2 overflow-hidden rounded-full bg-slate-100 dark:bg-dark-light">
                <div className="w-[70%] h-full bg-secondary rounded-full"></div>
              </div>
            </div>

            <div className="mt-2">
              <div className="flex-center-between">
                <h1 className="font-semibold capitalize">Mutlu Müşteriler</h1>
                <h1 className="font-semibold capitalize">90%</h1>
              </div>
              <div className="w-full h-2 mt-2 overflow-hidden rounded-full bg-slate-100 dark:bg-dark-light">
                <div className="w-[90%] h-full bg-secondary rounded-full"></div>
              </div>
            </div>

            <div className="mt-2">
              <div className="flex-center-between">
                <h1 className="font-semibold capitalize">Büyüme Hedefi</h1>
                <h1 className="font-semibold capitalize">85%</h1>
              </div>
              <div className="w-full h-2 mt-2 overflow-hidden rounded-full bg-slate-100 dark:bg-dark-light">
                <div className="w-[85%] h-full bg-secondary rounded-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;