import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Filters = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="md:max-w-[100%] w-full mx-auto relative -mt-44 sm:-mt-60">
      <Slider {...settings}>
        <div>
          <img src="/images/birlikSlider1.png" alt="Görsel 1" className="w-full h-300" />
        </div>
        <div>
          <img src="/images/birlikSlider3.png" alt="Görsel 3" className="w-full h-300" />
        </div>
        <div>
          <img src="/images/birlikSlider5.png" alt="Görsel 5" className="w-full h-300" />
        </div>
        <div>
          <img src="/images/birlikSlider4.png" alt="Görsel 4" className="w-full h-300" />
        </div>
        <div>
          <img src="/images/birlikSlider2.png" alt="Görsel 2" className="w-full h-300" />
        </div>
      </Slider>
    </div>
  );
};

export default Filters;