import { Projects } from "../components/common/page-componets";

const Property = () => {
  return (
    <div className="pt-16 px-[3%] md:px-[6%]">
      <Projects/>
    </div>
  );
};

export default Property;
