export const navLinks = [
  {
    id: 1,
    linkText: "Ana Sayfa",
    url: "/",
  },
  {
    id: 2,
    linkText: "Hakkında",
    url: "/about-2",
  },
  {
    id: 3,
    linkText: "Hizmetler",
    url: "/services",
  },
  {
    id: 4,
    linkText: "Projelerimiz",
    url: "/property",
  },
  {
    id: 6,
    linkText: "İletişim",
    url: "/contact",
  },
];
