
const OverView = () => {
  return (
    <div className="pt-20 pb-0 -mb-24 -mt-24 md:mt-0">
      <div className="flex flex-wrap gap-4">
        <div className="flex-1 basis-[18rem]">
          <h1 className="heading">Neler Yapıyoruz</h1>
          <p className="mt-3">
            Samsun Ataşehir Vefa Konutları satışlarımız devam ediyor. Siz de hayalini kurduğunuz eve kavuşma fırsatı
            için bizi arayabilir detaylı bilgi için ofisimizi ziyaret edebilirsiniz. Dilerseniz Youtube videomuzu da
            izleyebilirsiniz.
          </p>
          <button className="mt-4 btn btn-primary">Youtube: BİRLİK İNŞAAT</button>
        </div>
        <div className="flex-1 basis-[18rem]">
          <div className="relative overflow-hidden rounded-lg">
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/y8mezVDH_5k"
              title="YouTube video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="object-cover"
            ></iframe>

          </div>
        </div>
      </div>
    </div>
  );
};

export default OverView;